import React from 'react';
import PropTypes from 'prop-types';

import { StyledBulletPoint, Marker, MarkerText, TextContainer, Heading } from "./BulletPoint.styles";

const BulletPoint = (props) => (
  <StyledBulletPoint className="bullet-point">
    <Marker className="bullet-point__marker">
      <MarkerText className="bullet-point__marker-text">{props.markerText}</MarkerText>
    </Marker>
    <TextContainer className="bullet-point__text-container">
      {props.heading && <Heading className="bullet-point__heading">{props.heading}</Heading>}
      {props.body && <p className="bullet-point__body">{props.body}</p>}
    </TextContainer>
  </StyledBulletPoint>
);

BulletPoint.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  markerText: PropTypes.string
};

BulletPoint.defaultProps = {
  heading: null,
  body: null,
  markerText: null
};

export default BulletPoint;
